import request from '@/utils/request'

//Login
export function loadPaginatedECShopCodes(page) {
  return request({ url: 'vecshopcodes', method: 'post', data: {'page': page} })
} 

export function loadPaginatedECPurchases(page) {
  return request({ url: 'vecpurchases', method: 'post', data: {'page': page} })
} 

export function loadPaginatedECSales(page) {
  return request({ url: 'vecsales', method: 'post', data: {'page': page} })
} 


export function loadPaginatedECWalletHistory(page) {
  return request({ url: 'vecwallethistory', method: 'post', data: {'page': page} })
} 

export function process_ecwalletReload(param) {
  return request({ url: 'processecwalletreload', method: 'post', data: param})
} 


export function process_ecwalletOTP(param) {
  return request({ url: 'ecwalletvalidateotp', method: 'post', data: param})
} 

