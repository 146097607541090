
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {loadPaginatedECPurchases}  from "@/api/onlineshop";
// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from  "@/views/ECShopAdvisory.vue";

export default defineComponent({
  name: "shoppurchases",
  components: {
    // BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  data() { 
    return { 
      list : [],
      loading: true,
    }
  },
  mounted(){
      this.loadPaginatedECPurchases();
  },
  methods : {
    async loadPaginatedECPurchases(){ 
      this.loading = true;
      const response = await loadPaginatedECPurchases(1);
      this.list = response.data.pageList;
      this.loading = false;
    },
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("onlineShopTab") || "0");
      setCurrentPageTitle("Online Shop");

    });
    
  }
});
